<template>
  <section
    class="relative w-screen h-full flex justify-center items-center min-h-screen content-center bg-gray-600"
  >
    <div class="container mx-auto h-full" style="max-width: 80%">
      <div class="flex content-center items-center justify-center h-full">
        <div class="w-full lg:w-4/12">
          <div
            class="relative mx-auto flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-200 border-0"
          >
            <div class="rounded-t mb-0 px-6 py-6">
              <div class="text-center mb-3">
                <h6 class="text-gray-500 font-bold text-xl">
                  {{ $t("Reset password") }}
                </h6>
              </div>
            </div>
            <div class="flex-auto px-4 lg:px-10 py-10 pt-0">
              <div
                v-if="message"
                class="border border-green-600 px-3 py-2 mb-3 text-green-600 bg-blueGray-100 text-xs"
              >
                <div class="text-lg font-extrabold text-green-700">
                  {{ $t(message) }}
                </div>
              </div>
              <div
                v-if="ErrorMessage"
                class="border border-red-600 px-3 py-2 mb-3 text-red-600 bg-blueGray-100 text-xs"
              >
                <div class="text-lg font-extrabold text-red-700">
                  {{ $t(ErrorMessage) }}
                </div>
              </div>
              <div
                class="flex justify-center text-red-600 font-semibold underline rounded px-4 py-1 my-3"
                v-if="showResetLink"
              >
                <router-link :to="{ name: 'PasswordReset' }">
                  {{ $t("request new reset link") }}
                </router-link>
              </div>
              <div
                v-if="password != password_confirmation"
                class="border border-red-600 px-3 py-2 mb-3 text-red-600 bg-blueGray-100 text-xs"
              >
                <div class="text-lg font-extrabold text-red-700">
                  {{ $t("passwords don't match") }}
                </div>
              </div>
              <form>
                <div class="relative w-full mb-3">
                  <label
                    class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                    for="grid-password"
                  >
                    {{ $t("Password") }}
                  </label>
                  <div
                    class="flex justify-center items-center bg-white shadow rounded px-1"
                  >
                    <input
                      v-model="password"
                      :type="showPassword ? 'text' : 'password'"
                      class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm focus:outline-none w-full"
                    />
                    <i
                      class="fas text-blueGray-500 mr-2 cursor-pointer hover:text-blueGray-400"
                      :class="{
                        'fa-eye-slash': showPassword,
                        'fa-eye': !showPassword
                      }"
                      @click="showPassword = !showPassword"
                    />
                  </div>
                </div>
                <div class="relative w-full mb-3">
                  <label
                    class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                    for="grid-password"
                  >
                    {{ $t("Password Confirmation") }}
                  </label>
                  <div
                    class="flex justify-center items-center bg-white shadow rounded px-1"
                  >
                    <input
                      v-model="password_confirmation"
                      :type="showPasswordConfirm ? 'text' : 'password'"
                      class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm focus:outline-none w-full"
                    />
                    <i
                      class="fas text-blueGray-500 mr-2 cursor-pointer hover:text-blueGray-400"
                      :class="{
                        'fa-eye-slash': showPasswordConfirm,
                        'fa-eye': !showPasswordConfirm
                      }"
                      @click="showPasswordConfirm = !showPasswordConfirm"
                    />
                  </div>
                </div>
                <div class="text-center mt-6">
                  <button
                    @click="handleSubmitPasswordReset"
                    class="text-white text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 w-full ease-linear transition-all duration-150"
                    type="button"
                    :disabled="!canSubmit"
                    :class="{
                      'bg-gray-800 active:bg-gray-600': canSubmit,
                      'cursor-not-allowed bg-gray-600': !canSubmit
                    }"
                  >
                    <i
                      class="fas fa-circle-notch fa-spin"
                      v-if="$store.state.resetPassword.loading"
                    />
                    {{ $t("Reset password") }}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "PasswordResetToken",
  data() {
    return {
      message: "",
      showPassword: false,
      showPasswordConfirm: false,
      ErrorMessage: "",
      password: "",
      password_confirmation: "",
      showResetLink: false
    };
  },
  computed: {
    canSubmit() {
      return (
        this.password &&
        this.password_confirmation &&
        this.password_confirmation == this.password
      );
    }
  },
  methods: {
    handleSubmitPasswordReset() {
      this.message = "";
      this.ErrorMessage = "";
      this.showResetLink = false;
      this.$store.dispatch("resetPassword/passwordReset", {
        token: this.$route.params.token,
        payload: {
          user: {
            password: this.password,
            password_confirmation: this.password_confirmation,
            agent: "web"
          }
        },
        cb: message => {
          this.message = message;
          this.$router.push({ name: "Login" });
        },
        errorCb: (ErrorMessage, showResetLink) => {
          this.ErrorMessage = ErrorMessage;
          this.showResetLink = showResetLink;
        }
      });
    }
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      if (vm.$store.state.accounts?.user?.token) {
        vm.$store.dispatch("accounts/logout", false);
      }
    });
  }
};
</script>
